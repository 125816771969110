import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { HeaderArrowIcon } from './icons';

const header = ({ type = 'page', image, heading, subheading }) => {
    return (
        <header className={`header ${type === 'home' ? 'home' : ''}`}>
            <Img fluid={image} className="header__image" />
            <div className="header__background" />
            <div className="header__content">
                {type === 'home' ? (
                    <h1 className="main-heading">
                        {heading}
                    </h1>
                ) : (
                    <h2 className="main-heading" dangerouslySetInnerHTML={{ __html: heading }} />
                )}
                {type === 'home' && (
                    <>
                        <h2 className="subheading">
                            {subheading}
                        </h2>
                        <Link to="/gerichte" className="btn btn-white">
                            menükarte
                        </Link>
                    </>
                )}
            </div>
            {type === 'home' && (
                <div className="header__cta">
                    <HeaderArrowIcon />
                </div>
            )}
        </header>
    )
}

export default header;